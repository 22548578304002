import React from 'react'
import livretCOMP from '../../assets/images/livre_blanc_compliance.png'
import {Link} from 'gatsby'

const ContentThree = () => {

    return (
        <div className="upcoming-bootcamps-area ptb-30">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-5 col-md-12">
                        <div className="project-start-image">
                            <img src={livretCOMP} alt="project" />
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-12">
                        <h2 className="subtitle">Téléchargez notre livret</h2>
                        <p>Comment intégrer un dispositif de signalement dans votre programme compliance ? </p>
                        <div className="col-lg-12 col-md-12">
                            <div className="form-livre-blanc">
                                <form id="contactForm" method="post" action="https://wspk.me/livreblanccompliance">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div id="livre-blanc">
                                                <input type="email" name="email" className="form-control" required placeholder="Email *" />
                                            </div>
                                        </div>

                                        <div className="col-md-12 ptb-10">
                                        <input style={{marginRight:'8px',marginTop:'-2px'}} type="checkbox" id="rgpd" name="fav_language" value="OUI" />
                                        <label style={{fontSize:'14px'}} for="rgpd">Je ne souhaite pas recevoir des newsletter de Seekreet</label>
                                        <p style={{fontSize:'12px',color:'grey'}}>Seekreet traite vos données à caractère personnel dans le cadre de votre demande de téléchargement de livre blanc et dans le cadre de ses actions de prospection commerciale. Pour en savoir plus sur la gestion de vos données personnelles et pour exercer vos droits, reportez-vous à <Link to="/mentions-legales">notre politique de confidentialité</Link>.</p>
                                        </div>

                                        <div className="col-md-12 center-btn pt-10">
                                            <button type="submit" className="default-btn">
                                                <i className="flaticon-tick"></i> 
                                                Envoyer <span></span>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContentThree;