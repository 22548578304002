import React from 'react'
import { Link } from 'gatsby'

const LienQuestionnaire = () => {
    return (
        <div className="upcoming-bootcamps-area ptb-70">
            <div className="container">
                <div className="upcoming-bootcamps-conten center cadre-fsc">
                    <h2 className="h2-top bottom">Testez votre niveau de maturité<br></br>FOOD SAFETY CULTURE</h2>
                    <p>Un questionnaire rapide pour vous donner une première vision de la mise en place des principaux piliers de votre démarche.</p>
                    <Link to="https://gerer.wispeek.com/s/gijnkERHuSFxekZRZ" target="_blank" className="default-btn">
                        <i className="flaticon-right"></i> 
                            J'accède au test <span></span>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default LienQuestionnaire;