import React from 'react'
import { Link } from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'

const RendezVous = () => {
    return (
        <section className="contact-area ptb-70">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="contact" /> 
                            Demande de rendez-vous
                    </span>
                    <h2>Réservez votre visio</h2>
                    <p>Cliquez sur le bouton, sélectionnez le créneau que vous souhaitez réservez. Vous recevrez par mail le lien de visio (pensez à vérifier vos spam).</p>
                </div>
                <div className="center">
                    <Link to="https://share.seekreet.com/index.php/apps/appointments/pub/T3Kl1pnmmsY6tw%3D%3D/form" target="_blank" className="default-btn">
                        <i className="flaticon-web"></i> 
                            Prendre rendez-vous 
                            <span></span>
                    </Link>
                </div>
            </div>
        </section>
    );
}

export default RendezVous